import React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import compact from 'lodash/compact';
import startCase from 'lodash/startCase';
import { useSelector } from 'react-redux';
import flattenDeep from 'lodash/flattenDeep';

import SEO from '../../components/seo';
import TemplateLayout from '../layout';
import InfoCard from '../../components/info-card';
import Breadcrumb from '../../components/breadcrumb';
import TagsSidebar from '../../components/tags-sidebar';
import CategoriesSidebar from '../../components/categories-sidebar';

import styles from './index.module.scss';

const CategoryPage = ({ pageContext }) => {
  const { windowWidth } = useSelector(({ global }) => global);
  const isMobile = windowWidth < 630;

  const nodes = map(get(pageContext, 'data.allPages.edges', []), 'node');
  const tags = uniq(flattenDeep(compact(map(nodes, '_meta.tags')))).sort();
  const category = get(pageContext, 'category', '');

  const nodesSortedByYears = sortBy(nodes, 'year');
  const nodesByCategory = { [category]: nodesSortedByYears };

  return (
    <TemplateLayout>
      <SEO title={`Datayears | Category | ${startCase(category)}`} description={`all posts on "${category}"`} />
      <Breadcrumb
        items={[
          { name: 'Home', linkTo: '/' },
          { name: 'Categories', linkTo: `/categories` },
          { name: startCase(category) },
        ]}
      />

      <div className={styles.container}>
        <div className={styles.cardsContainer}>
          {nodesSortedByYears.map((node, i) => (
            <div key={`${node.year}-${i}`}>
              <InfoCard 
                key={get(node, '_meta.uid')} 
                node={node}
                minimal={isMobile}
              />
            </div>
          ))}
        </div>

        <div className={styles.sidebarContainer}>
          <CategoriesSidebar nodesByCategory={nodesByCategory} />
          <TagsSidebar tags={tags} />
        </div>

        <div className={styles.clear} />
      </div>
    </TemplateLayout>
  );
};

CategoryPage.propTypes = {
  pageContext: PropTypes.shape({
    category: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
  }).isRequired,
};

export default CategoryPage;
